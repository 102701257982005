@use "@angular/material" as mat;
@use "appiworks-theme" as theme;
@import "./component-themes";

@include mat.core();

// $primary: mat.define-palette(mat.$indigo-palette);
// $accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
// $warn: mat.define-palette(mat.$red-palette);

$primary: theme.$mat-theme-primary;
$accent: theme.$mat-theme-accent;
$warn: theme.$mat-theme-warn;

$theme: mat.define-light-theme((
 color: (
   primary: $primary,
   accent: $accent,
   warn: $warn,
 ),
 typography: mat.define-typography-config(),
 density: 0,
));

@include mat.all-component-themes($theme);

// Indigo & Pink
.aw-brand {
  $primary: mat.define-palette(theme.$mat-theme-primary);
  $accent: mat.define-palette(theme.$mat-theme-accent);
  $warn: mat.define-palette(theme.$mat-theme-warn);

  $aw-brand-theme: mat.define-light-theme((
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  ));

  @include mat.all-component-colors($aw-brand-theme);
}


// Indigo & Pink dark
.aw-brand-dark {
  $primary: mat.define-palette(theme.$mat-theme-primary);
  $accent: mat.define-palette(theme.$mat-theme-accent);
  $warn: mat.define-palette(theme.$mat-theme-warn);

  $aw-brand-theme-dark: mat.define-dark-theme((
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  ));

  @include mat.all-component-colors($aw-brand-theme-dark);
}

// Deep Purple & Amber
.deeppurple-amber {
  $primary: mat.define-palette(mat.$deep-purple-palette);
  $accent: mat.define-palette(mat.$amber-palette);
  $warn: mat.define-palette(mat.$red-palette);

  $deeppurple-amber-theme: mat.define-light-theme((
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  ));

  @include mat.all-component-colors($deeppurple-amber-theme);
}

// Deep Purple & Amber Dark
.deeppurple-amber-dark {
  $dark-primary: mat.define-palette(mat.$deep-purple-palette);
  $dark-accent: mat.define-palette(mat.$amber-palette);
  $dark-warn: mat.define-palette(mat.$red-palette);

  $deeppurple-amber-dark-theme: mat.define-dark-theme((
    color: (
      primary: $dark-primary,
      accent: $dark-accent,
      warn: $dark-warn,
    ),
    density: 0,
  ));

  @include mat.all-component-colors($deeppurple-amber-dark-theme);
}

// Indigo & Pink
.indigo-pink {
  $primary: mat.define-palette(mat.$indigo-palette);
  $accent: mat.define-palette(mat.$pink-palette);
  $warn: mat.define-palette(mat.$red-palette);

  $indigo-pink-theme: mat.define-light-theme((
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
    // typography: mat.define-typography-config(),
    density: 0,
  ));

  @include mat.all-component-colors($indigo-pink-theme);
}


// Indigo & Pink dark
.indigo-pink-dark {
  $primary: mat.define-palette(mat.$indigo-palette);
  $accent: mat.define-palette(mat.$pink-palette);
  $warn: mat.define-palette(mat.$red-palette);

  $themeIndigoPinkDark: mat.define-dark-theme((
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
    // typography: mat.define-typography-config(),
    density: 0,
  ));

  @include mat.all-component-colors($themeIndigoPinkDark);
}

//Pink & Blue Grey
.pink-bluegrey {
  $darkPrimary: mat.define-palette(mat.$pink-palette);
  $darkAccent: mat.define-palette(mat.$blue-grey-palette);
  $darkWarn: mat.define-palette(mat.$red-palette);

  $themePinkBlueGrey: mat.define-light-theme((
    color: (
      primary: $darkPrimary,
      accent: $darkAccent,
      warn: $darkWarn,
    ),
    // typography: mat.define-typography-config(),
    density: 0,
  ));

  @include mat.all-component-colors($themePinkBlueGrey);
}

//Pink & Blue Grey Dark
.pink-bluegrey-dark {
  $darkPrimary: mat.define-palette(mat.$pink-palette);
  $darkAccent: mat.define-palette(mat.$blue-grey-palette);
  $darkWarn: mat.define-palette(mat.$red-palette);

  $themePinkBlueGreyDark: mat.define-dark-theme((
    color: (
      primary: $darkPrimary,
      accent: $darkAccent,
      warn: $darkWarn,
    ),
    density: 0,
  ));

  @include mat.all-component-colors($themePinkBlueGreyDark);
}

//Purple & Green
.purple-green {
  $darkPrimary: mat.define-palette(mat.$purple-palette);
  $darkAccent: mat.define-palette(mat.$green-palette);
  $darkWarn: mat.define-palette(mat.$red-palette);

  $themePurpleGreen: mat.define-light-theme((
    color: (
      primary: $darkPrimary,
      accent: $darkAccent,
      warn: $darkWarn,
    ),
    density: 0,
  ));

  @include mat.all-component-colors($themePurpleGreen);
}

//Purple & Green Dark
.purple-green-dark {
  $darkPrimary: mat.define-palette(mat.$purple-palette);
  $darkAccent: mat.define-palette(mat.$green-palette);
  $darkWarn: mat.define-palette(mat.$red-palette);

  $themePurpleGreenDark: mat.define-dark-theme((
    color: (
      primary: $darkPrimary,
      accent: $darkAccent,
      warn: $darkWarn,
    ),
    density: 0,
  ));

  @include mat.all-component-colors($themePurpleGreenDark);
}
